import {Component, OnDestroy, OnInit} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';


declare var $: any;
@Component({
  selector: 'app-not-accessible',
  templateUrl: './not-accessible.component.html',
  styleUrls: ['./not-accessible.component.css']
})
export class NotAccessibleComponent  implements OnInit {

  constructor(private ngxService: NgxUiLoaderService) {
    this.ngxService.stop();
    window.scrollTo(0, 0);
  }

  ngOnInit() {
  }

}
