import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse }   from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import {Router} from '@angular/router';
import { retry } from 'rxjs/operators';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private router: Router) {}
  // intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    //
    // return next.handle(req).pipe(
    //   tap(evt => {
    //     if (evt instanceof HttpResponse) {
    //     }
    //   }),
    //   catchError((err: any) => {
    //     if(err instanceof HttpErrorResponse) {
    //       // if( err.status === 403 ) {
    //       //   this.router.navigate(['/not-accessible']);
    //       // }
    //     }
    //     return of(err);
    //   }));
  // }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /**
     * continues request execution
     */
    return next.handle(request).pipe(catchError((error, caught) => {
      this.handleAuthError(error);
      return of(error);
    }) as any);
  }


  /**
   * manage errors
   * @param err
   * @returns {any}
   */
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 403) {
      this.router.navigate(['/not-accessible']);
      return of(err.message);
    }
    throw err;
  }

}
