import {Component, OnDestroy, OnInit} from '@angular/core';
import { EventsService } from 'angular4-events';
import {DataService} from '../../shared/data.service';
import {Subscription} from 'rxjs';

declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {

  // Get user role function start
  private subscription: Subscription;
  get userRole(): any {
    var role =  localStorage.getItem('user_type');
    if ( role === null || role === undefined ) {
      return 0;
    } else {
      return role;
    }
  }
  // Get user role function end

  // Get restaurant name function start
  get restaurant_name(): any {
    return localStorage.getItem('restaurant_name') || '';
  }
  // Get restaurant name function end

  permissions: any;
  permissionsEvent: any;
  constructor(private events: EventsService, private dataService: DataService) {
    window.scrollTo(0, 0);
    this.permissionsEvent = this.events.subscribe('permissions').subscribe((data) => {
      this.permissions = JSON.parse(localStorage.getItem('permissions')) || [];
    });
  }


  // Add active class function start
  addActiveClass(id) {
    $('.sidebarMenu').removeClass('active');
    $('#' + id).addClass('active');
  }
  // Add active class function end


  ngOnInit() {
    this.permissions = JSON.parse(localStorage.getItem('permissions')) || [];
  }

  ngOnDestroy() {
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }
  }

}
