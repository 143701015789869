import {Component, OnDestroy, OnInit} from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit, OnDestroy {

  constructor() {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    $('#header').css('display', 'none');
    $('#sidebarMain').css('display', 'none');
  }
  ngOnDestroy(): void {
    $('#header').css('display', 'block');
    $('#sidebarMain').css('display', 'block');
  }

}
