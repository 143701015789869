export class Constants {
  // static apiAddress = 'https://backend.twidlee.com/'; // Live URL
  // static apiAddress = 'http://3d9f3fb29bf8.ngrok.io/';
  static apiAddress = 'http://52.73.221.180/'; // Staging URL
  // static apiAddress = 'http://3.15.233.217/'; // Development URL
  // static apiAddress = 'http://18.204.149.232/'; // Auto scaling URL
  // static apiAddress = 'http://34.197.68.243/'; // Backend Live URL
  // static apiAddress = 'http://192.168.0.114:8000/'; // Local URL

}
