import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../shared/data.service';
import {Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
import {Subscription} from 'rxjs';

declare var $: any;
declare var Chart: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  get userData(): any {
    return JSON.parse(localStorage.getItem('user')) || [];
  }
  constructor(private dataService: DataService, private router: Router) {
    window.scrollTo(0, 0);
  }

  // Logout function start
  logout() {
    window.localStorage.setItem('isAuthenticated', 'false');
    window.localStorage.setItem('token', '');
    window.localStorage.setItem('user_type', '');
    window.localStorage.setItem('restaurant_name', '');
    window.localStorage.setItem('permissions', '[]');
    window.localStorage.setItem('user', '[]');
    this.router.navigate(['/login']);
  }
  // Logout function end

  ngOnInit() {
    $.getScript('../../assets/js/jquery.app.js');
    $('#open-left').click(function() {
      $.getScript('../../assets/js/jquery.app.js');
    });
  }

  ngOnDestroy() {
  }


}
