import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appToUpperCase]'
})
export class ToUpperCaseDirective {

  constructor() { }


  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const iChars = '!#$%^&*()+=-[]\';,/{}|\:<>?~_';
    if ( iChars.includes(event.key)) {
      event.preventDefault();
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('input', false, true);
      event.target.dispatchEvent(evt);
    }
    // if (event.keyCode > 32 && event.keyCode < 128) {
    //   event.target['value'] += event.key.toUpperCase();
    //   event.preventDefault();
    //   var evt = document.createEvent('HTMLEvents');
    //   evt.initEvent('input', false, true);
    //   event.target.dispatchEvent(evt);
    // }

  }

}

