import { BrowserModule } from '@angular/platform-browser';
import {enableProdMode, NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HttpClientModule } from '@angular/common/http';
import { EventsModule } from 'angular4-events';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './shared/interceptor.service';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
  NgxUiLoaderRouterModule,
  NgxUiLoaderHttpModule
} from 'ngx-ui-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { NotFoundComponent } from './not-found/not-found.component';
import {ToUpperCaseDirective} from './shared/uppercase.directive';
import { HeaderComponent } from './layouts/header/header.component';
import {LoginComponent} from './login/login.component';
import {LayoutsModule} from './layouts/layouts.module';
import { NotAccessibleComponent } from './not-accessible/not-accessible.component';
// import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';



const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#OOACC1',
  bgsOpacity: 0.5,
  // bgsPosition: POSITION.bottomCenter,
  // bgsSize: 60,
  bgsType: SPINNER.rectangleBounce,
  // fgsColor: '#00ACC1',
  // fgsPosition: POSITION.centerCenter,
  // fgsSize: 60,
  fgsType: SPINNER.ballScaleMultiple,
  logoUrl: 'assets/images/loder-red.gif',
  // pbColor: '#FF0000',
  // pbDirection: PB_DIRECTION.leftToRight,
  // pbThickness: 5,
  // text: 'Welcome to ngx-ui-loader',
  // textColor: '#FFFFFF',
  // textPosition: POSITION.centerCenter
};
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ToUpperCaseDirective,
    LoginComponent,
    NotAccessibleComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ImageCropperModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    EventsModule,
    LayoutsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
